:root {
  --palette-color-1: #0A2342;
  --palette-color-2: #F9F7F0;
  --palette-color-3: #848FA5;
}

/*.square {*/
/*  background: #fff;*/
/*  border: 1px solid #999;*/
/*  float: left;*/
/*  font-size: 24px;*/
/*  font-weight: bold;*/
/*  line-height: 34px;*/
/*  height: 34px;*/
/*  margin-right: -1px;*/
/*  margin-top: -1px;*/
/*  padding: 0;*/
/*  text-align: center;*/
/*  width: 34px;*/
/*}*/

.board-row:after {
  clear: both;
  content: '';
  display: table;
}

.status {
  margin-bottom: 10px;
}
.game {
  display: flex;
  flex-direction: row;
}

.game-info {
  margin-left: 20px;
}

.paper {
  overflow: hidden;
}

.img {
  width: 100%;
  height: 5rem;
}
